import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import ConfigTable01 from "components/Internet_Access/Port_Forwarding/Zyxel_Speedlink/ConfigTable01";
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding - Zyxel Speedlink",
  "path": "/Internet_Access/Port_Forwarding/Zyxel_Speedlink/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding_Pirelli.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_Pirelli.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Port Forwarding - Zyxel Speedlink' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding.png' twitter='/images/Search/IA_SearchThumb_Portforwarding.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/Zyxel_Speedlink/' locationFR='/fr/Internet_Access/Port_Forwarding/Zyxel_Speedlink/' crumbLabel="Zyxel Speedlink" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "zyxel-speedlink",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#zyxel-speedlink",
        "aria-label": "zyxel speedlink permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zyxel Speedlink`}</h2>
    <p>{`Dies ist der 2. Schritt der Portweiterleitung, klicken Sie `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`HIER`}</a>{` um zum Anfang der Anleitung zu gelangen.`}</p>
    <p>{`Öffnen Sie die Weboberfläche Ihres Zyxel Speedlink Routers indem Sie in die Adresszeile Ihres Browsers die IP Adresse des Routers eingeben (standardmäßig 192.168.100.1).`}</p>
    <p>{`Wechseln Sie nach der Anmeldung die Ansicht auf `}<strong parentName="p">{`Entwickler`}</strong>{`. Navigieren Sie dann im Menü zu `}<strong parentName="p">{`Sicherheit - Port-Freigaben- Einstellungen`}</strong>{`. Klicken Sie dann auf `}<strong parentName="p">{`Neu`}</strong>{`, um eine neue Portweiterleitung einzutragen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ee70d16f5b317105f72014c886f8c694/218a4/Zyxel_Speedlink_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABjElEQVQoz5WSTW/UMBCG8//5AxwRnJAqVaqEuBQkegCpAWlFoReaj92SbBInjh07n/vxIBu2KogDWHo0Y8szfsczwW63Y5lnlsOBuRLo8CPt+w+Y21u0Feihxo4KY0e6fgKOHA4Hj1vjOLJarQjDkLZtCVxCx36/Z5kX6kwg43tkrRGtpVY992WHMiPH4ynZT+v2Ls4l7fueeZ4J8iwnihLqusFYizaGQtSUVUVRllRVRVlW1HXtA/9kGIbf9kHTlGySb0xjx34ZKIuC6O6OOIqJ45g0TUmSlCiKSeLE+w53vl5v2G63ZFlOnucopQmuLt5x9vw15y+veHMdM80zxhiPtfbBd0qGfvClnXCKXMmPCT69uODVk2dcPj3n89cMJSXVtvCltq1CKeU/2+F8rbUv3z3SdR1N03grhEBKSTCtE/K3l+xuVjD16JsvqOuQSWnGafIqTk37G645J+sICiFIv2eUUqK6DislkzF+JFwX3Zgcf13+FwJRVWzSNWmSePnTsjAvy6MR+T9+AExgrFE2GXh5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee70d16f5b317105f72014c886f8c694/e4706/Zyxel_Speedlink_01.avif 230w", "/en/static/ee70d16f5b317105f72014c886f8c694/d1af7/Zyxel_Speedlink_01.avif 460w", "/en/static/ee70d16f5b317105f72014c886f8c694/7f308/Zyxel_Speedlink_01.avif 920w", "/en/static/ee70d16f5b317105f72014c886f8c694/2bd13/Zyxel_Speedlink_01.avif 1052w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ee70d16f5b317105f72014c886f8c694/a0b58/Zyxel_Speedlink_01.webp 230w", "/en/static/ee70d16f5b317105f72014c886f8c694/bc10c/Zyxel_Speedlink_01.webp 460w", "/en/static/ee70d16f5b317105f72014c886f8c694/966d8/Zyxel_Speedlink_01.webp 920w", "/en/static/ee70d16f5b317105f72014c886f8c694/c18de/Zyxel_Speedlink_01.webp 1052w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ee70d16f5b317105f72014c886f8c694/81c8e/Zyxel_Speedlink_01.png 230w", "/en/static/ee70d16f5b317105f72014c886f8c694/08a84/Zyxel_Speedlink_01.png 460w", "/en/static/ee70d16f5b317105f72014c886f8c694/c0255/Zyxel_Speedlink_01.png 920w", "/en/static/ee70d16f5b317105f72014c886f8c694/218a4/Zyxel_Speedlink_01.png 1052w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ee70d16f5b317105f72014c886f8c694/c0255/Zyxel_Speedlink_01.png",
              "alt": "Portforwarding Zyxel Speedlink",
              "title": "Portforwarding Zyxel Speedlink",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Geben Sie im darauffolgenden Fenster dann folgende Daten ein:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable01 mdxType="ConfigTable01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Durch einen Klick auf Speichern wird die Portweiterleitung aktiviert.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "834px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/5d72a/Zyxel_Speedlink_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnklEQVQ4y5VTi5KCMAzs/38iKL6gFSggUASfe7PBcp6jONeZTJuWbDbJojabDaJohdVqhTBcYLFYYL1ew1qLoihkt7ZAQSuK6U7O9tfP8xxt20KlaYr9fg/uWZYhThIYY5AkCXSiJaDrOrjWoXMdnHMSyDva8Xic7s7nM9ThcBCwPB+zjJkcmqYF35qmEasfO+/qupbz/X7H86KvXNsiCELEcYI4jmHMHnOLQbfbbdpfTZFuWZaSmeXVdYO+73HsuulMNs6NJZ5Opz9gzwmEodZGhsB+sXcs53K5SGDfD9KXoe/Fp/HtEzthSFYES9NsYskgLp/5teQ5U5ys0QZ5lk/T8k2vqkrAP7F5y3C328mUyY69JAh96tJPnfdeInM2DAMUJ0vNEcgDUqgUeBAEMnkqgOxlWB/AmFAAl8slttutALF/ZMoEBLMP3+vPV/GO7QSotR6nrI2A+pIJyHbw1/Tl+99stuQoioQhh8MAMuCZzFl2GAayk8G3kvmuvFipN681PtC/Xq+TcdrfjN+psqxEJtaOsnnW2n/k4mXzAykPgMI3RiqKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c12ccb8bb9e82cd24bc0478c314c925/e4706/Zyxel_Speedlink_02.avif 230w", "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/d1af7/Zyxel_Speedlink_02.avif 460w", "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/83fca/Zyxel_Speedlink_02.avif 834w"],
              "sizes": "(max-width: 834px) 100vw, 834px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c12ccb8bb9e82cd24bc0478c314c925/a0b58/Zyxel_Speedlink_02.webp 230w", "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/bc10c/Zyxel_Speedlink_02.webp 460w", "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/e1262/Zyxel_Speedlink_02.webp 834w"],
              "sizes": "(max-width: 834px) 100vw, 834px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c12ccb8bb9e82cd24bc0478c314c925/81c8e/Zyxel_Speedlink_02.png 230w", "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/08a84/Zyxel_Speedlink_02.png 460w", "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/5d72a/Zyxel_Speedlink_02.png 834w"],
              "sizes": "(max-width: 834px) 100vw, 834px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c12ccb8bb9e82cd24bc0478c314c925/5d72a/Zyxel_Speedlink_02.png",
              "alt": "Portforwarding Zyxel Speedlink",
              "title": "Portforwarding Zyxel Speedlink",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Um auch auf das Flash-Video zugreifen zu können, muss nun für Ihre Kamera noch eine zweite Portweiterleitung eingerichtet werden. Gehen Sie dafür genau nach der vorigen Anleitung vor, nur tragen Sie im letzten Fenster den RMTP-Port Ihrer Kamera ein. Für Ihre erste Kamera also in alle Felder 1935, für Ihre zweite Kamera 1936, usw.`}</p>
    <p>{`Damit sind die Einstellungen im Router fertig gestellt und Sie können mit dem nächsten Schritt fortfahren. Klicken Sie dazu `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Mobile_Access/"
      }}>{`HIER`}</a>{`.`}</p>
    <h2 {...{
      "id": "iii-schritt-kamera-adresse-auslesen-und-fernzugriff-auf-die-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-schritt-kamera-adresse-auslesen-und-fernzugriff-auf-die-kamera",
        "aria-label": "iii schritt kamera adresse auslesen und fernzugriff auf die kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III. Schritt: Kamera-Adresse auslesen und Fernzugriff auf die Kamera`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      